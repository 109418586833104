import styled from 'styled-components'
import { Link } from 'gatsby'

export const BlogsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding: 2rem;
  margin: 3rem 0;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (min-width: 1366px) {
    grid-template-columns: repeat(4, 1fr);
  }
`
export const BlogCard = styled(Link)`
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s;
  text-decoration: none;
  :hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
`
export const BlogData = styled.div`
  padding: 1rem;
`
export const BlogTitle = styled.p`
  font-size: 1.3rem;
  font-weight: bold;
  color: ${({ theme: { colors } }) => colors.primary};
  margin: 0.2rem 0;
`
export const BlogDesc = styled.p`
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray};
  margin: 0.5rem 0;
`
export const BlogLink = styled(Link)`
  background: ${({ theme: { colors } }) => colors.primary};
  padding: 0.4rem 0.6rem;
  color: #ffffff;
  text-decoration: none;
  font-size: 0.8rem;
  margin: 0.5rem;
  display: inline-block;
`
export const ReadTime = styled.span`
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray2};
`

export const PostDate = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: ${({ theme: { colors } }) => colors.gray2};
  font-weight: 400;
`
export const PostTags = styled.p`
  margin: 0;
  margin-bottom: 0.6rem;
`

export const PostTag = styled.span`
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.gray2};
  font-size: 0.75rem;
  margin-right: 1rem;
`
