/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components_v2/seo'
import {
  BlogsContainer,
  BlogCard,
  BlogTitle,
  BlogDesc,
  BlogLink,
  BlogData,
  ReadTime,
  PostDate,
  PostTags,
  PostTag,
} from '../styles/Blog.style'
// import { BlogTitle } from '../components/Blog.style'
import { colors } from '../utils'
import blogCover from '../images/blog-cover.webp'
import LayoutV2 from '../components_v2/Layout'

// eslint-disable-next-line no-unused-vars
const BlogIndex = ({ data }) => {
  const posts = data.allMarkdownRemark.edges
  const [filterArg, setArg] = useState('')
  useEffect(() => {
    /*     if (typeof window !== `undefined`) {
      const indexOf = window.location.href.indexOf('tag=')
      if (indexOf > 0) setTag(window.location.href.substring(indexOf + 4).replace('%20', ' '))
    }
 */

    if (typeof window !== `undefined`) {
      if (window.location.href.indexOf('tag=') !== -1) {
        const indexOf = window.location.href.indexOf('tag=')
        if (indexOf > 0) setArg(window.location.href.substring(indexOf + 4).replace('%20', ' '))
      }
      if (window.location.href.indexOf('author=') !== -1) {
        const indexOf = window.location.href.indexOf('author=')
        if (indexOf > 0) setArg(window.location.href.substring(indexOf + 7).replace('%20', ' '))
      }
    }
  }, [])

  return (
    <LayoutV2>
      <SEO
        title="Zaat's Blog"
        image={blogCover}
        location="https://zaat.dev/blog"
        description="Latest trends & news in software and tech world"
      />
      <BlogsContainer>
        {posts
          .filter(({ node }) => node.frontmatter.tags.indexOf(filterArg) !== -1 || node.frontmatter.author_github.indexOf(filterArg) !== -1)
          .map(({ node }) => {
            const title = node.frontmatter.title || node.excerpt
            return (
              <BlogCard
                to={node.fields.slug}
                css={`
                  background: #ffffff;
                `}
              >
                <Img fluid={node.frontmatter.image.childImageSharp.thumbnail} />
                <BlogData to={node.fields.slug}>
                  <BlogTitle>{title}</BlogTitle>
                  <PostTags>
                    {node.frontmatter.tags.split(', ').map(tag => (
                      <PostTag>
                        <span
                          css={`
                            color: ${colors.gray};
                          `}
                        >
                          #
                        </span>
                        {tag}
                      </PostTag>
                    ))}
                  </PostTags>
                  <PostDate>
                    {node.frontmatter.date} - <ReadTime>{node.fields.readingTime.text}</ReadTime>
                  </PostDate>
                  <BlogDesc>{node.frontmatter.description}</BlogDesc>
                </BlogData>
                <div>
                  <BlogLink to={node.fields.slug}>Read More..</BlogLink>
                </div>
              </BlogCard>
            )
          })}
      </BlogsContainer>
    </LayoutV2>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/blog-page/blog/" } }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            description
            title
            tags
            author_github
            date(formatString: "DD MMMM, YYYY")
            image {
              childImageSharp {
                thumbnail: fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
